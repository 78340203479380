import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import { TapEffect } from "./AnimationEffects"


const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(6),
    padding: "1px",
    backgroundColor: "#ffffff",
    [theme.breakpoints.up("xl")]: {
      height: "60px"
    }
  },

  inner: {
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        color: "black",
        backgroundColor: "white"
      }
    }
  },

  inverse: {
    backgroundColor: "white",
    color: "black",
    [`@media (hover: hover)`]: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },

  noBorder: {
    padding: 0
  },

  small: {
    "fontSize": theme.typography.h6.fontSize,
    height: "22px"
  }
}))


export interface Props {
  text: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  className?: string
  inverse?: boolean
  noBorder?: boolean
  style?: "small" | "normal" | null

}

export default function StyledButton(props: Props) {
  const classes = useStyles()

  const rootStyle = clsx({
    [classes.root]: true, //always apply
    [props.className ?? ""]: true,
    [classes.noBorder]: props.noBorder ?? false,
    [classes.small]: props.style === "small"
  })

  const innerStyle = clsx({
    [classes.inner]: true, //always apply
    [classes.inverse]: props.inverse ?? false
  })

  return (

    <div className={rootStyle} onClick={props.onClick}>
      <div className={innerStyle}>
        <Typography style={{ width: "100%", textAlign: "center" }} variant={"button"}>
          <TapEffect>{props.text}</TapEffect>
        </Typography>
      </div>
    </div>
  )
}