import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import StyledButton from "../components/StyledButton"
import { makeStyles } from "@material-ui/core/styles"
import { BGBlurImage } from "../components/BGBlurImage"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  message: {
    width: "70%"
  },
  homeButton: {
    width: "260px",
    margin: `${theme.spacing(6)}px auto 0px auto`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
      query {
          bg : file(relativePath: { eq: "641-a-9278@3x.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 2048, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  return <Layout>
    <SEO title="404: Not found"/>
    <BGBlurImage imageUrl={""} localImage={data.bg.childImageSharp.fluid}/>
    <div className={classes.root}>
      <Typography className={classes.message} variant={"h2"} align={"center"}>Oh no! You just hit a page that
        doesn&#39;t exist... the sadness :(</Typography>
      <Link to="/"><StyledButton className={classes.homeButton} text="Browse classes"/></Link>
    </div>
  </Layout>
}

export default NotFoundPage
