import Img, { FluidObject } from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import clsx from "clsx"


export interface Props {
  localImage?: FluidObject[]
  imageUrl: string,
  lightBlur?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: -5,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    position: "absolute"
  },

  holder: {
    width: "100%",
    height: "100%",
    position: "relative"
  },

  image: {
    zIndex: -4,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute"
  },

  tint: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: "0",
    left: "0",
    zIndex: -3,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    "-webkit-backdrop-filter": "blur(8px)",
    "backdrop-filter": "blur(8px)"
  },

  lightTint: {
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    "-webkit-backdrop-filter": "blur(1.2px)",
    "backdrop-filter": "blur(1.2px)"
  }

}))

export function BGBlurImage(props: Props) {
  const classes = useStyles()

  return <div className={classes.root}>
    <div className={classes.holder}>
      {props.localImage == null && <img className={classes.image} src={props.imageUrl}/>}
      {props.localImage != null &&
      <Img style={{ position: `absolute` }} className={classes.image} fluid={props.localImage}/>}
      <div className={clsx({ [classes.tint]: true, [classes.lightTint]: props.lightBlur })}/>
    </div>
  </div>
}